define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    businessSubscription: (0, _computed.equal)("subscriptionType", "business"),
    communitySubscription: (0, _computed.equal)("subscriptionType", "community"),
    standardSubscription: (0, _computed.equal)("subscriptionType", "standard"),
    showApi: (0, _computed.or)("businessSubscription", "communitySubscription")
  });
});