define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards", ["exports", "discourse/routes/discourse", "discourse/lib/ajax", "@ember/service"], function (_exports, _discourse, _ajax, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.inject)(),
    model() {
      return (0, _ajax.ajax)("/admin/wizards");
    },
    setupController(controller, model) {
      controller.setProperties({
        subscribed: model.subscribed,
        subscriptionType: model.subscription_type,
        subscriptionAttributes: model.subscription_attributes,
        subscriptionClientInstalled: model.subscription_client_installed
      });
    },
    afterModel(model, transition) {
      if (transition.targetName === "adminWizards.index") {
        this.router.transitionTo("adminWizardsWizard");
      }
    }
  });
});