define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-container", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription"], function (_exports, _component, _decorators, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_subscription.default, dt7948.p({
    classNameBindings: [":wizard-subscription-container", "subscribed"],
    subscribedIcon(subscribed) {
      return subscribed ? "check" : "times";
    },
    subscribedLabel(subscribed) {
      return `admin.wizard.subscription.${subscribed ? "subscribed" : "not_subscribed"}.label`;
    },
    subscribedTitle(subscribed) {
      return `admin.wizard.subscription.${subscribed ? "subscribed" : "not_subscribed"}.title`;
    }
  }, [["method", "subscribedIcon", [(0, _decorators.default)("subscribed")]], ["method", "subscribedLabel", [(0, _decorators.default)("subscribed")]], ["method", "subscribedTitle", [(0, _decorators.default)("subscribed")]]]));
});