define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-badge", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription", "discourse/lib/url", "I18n"], function (_exports, _component, _decorators, _subscription, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_subscription.default, dt7948.p({
    tagName: "a",
    classNameBindings: [":wizard-subscription-badge", "subscriptionType"],
    attributeBindings: ["title"],
    i18nKey(type) {
      return `admin.wizard.subscription.type.${type ? type : "none"}`;
    },
    title(i18nKey) {
      return _I18n.default.t(`${i18nKey}.title`);
    },
    label(i18nKey) {
      return _I18n.default.t(`${i18nKey}.label`);
    },
    click() {
      _url.default.routeTo(this.subscriptionLink);
    }
  }, [["method", "i18nKey", [(0, _decorators.default)("subscriptionType")]], ["method", "title", [(0, _decorators.default)("i18nKey")]], ["method", "label", [(0, _decorators.default)("i18nKey")]]]));
});