define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-step", ["exports", "@ember/object", "discourse/plugins/discourse-custom-wizard/discourse/mixins/valid-state", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/runloop", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard"], function (_exports, _object, _validState, _ajax, _decorators, _runloop, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(_validState.default, dt7948.p({
    id: null,
    i18nKey(wizardId, stepId) {
      return `${wizardId}.${stepId}`;
    },
    translatedTitle(i18nKey, title) {
      return (0, _wizard.translationOrText)(`${i18nKey}.title`, title);
    },
    translatedDescription(i18nKey, description) {
      return (0, _wizard.translationOrText)(`${i18nKey}.description`, description);
    },
    displayIndex: index => index + 1,
    fieldsById(fields) {
      const lookup = {};
      fields.forEach(field => lookup[field.get("id")] = field);
      return lookup;
    },
    validate() {
      let allValid = true;
      const result = {
        warnings: []
      };
      this.fields.forEach(field => {
        allValid = allValid && field.check();
        const warning = field.get("warning");
        if (warning) {
          result.warnings.push(warning);
        }
      });
      this.setValid(allValid);
      return result;
    },
    fieldError(id, description) {
      const field = this.fields.findBy("id", id);
      if (field) {
        field.setValid(false, description);
      }
    },
    save() {
      const wizardId = this.get("wizardId");
      const fields = {};
      this.get("fields").forEach(f => {
        if (f.type !== "text_only") {
          fields[f.id] = f.value;
        }
      });
      return (0, _ajax.ajax)({
        url: `/w/${wizardId}/steps/${this.get("id")}`,
        type: "PUT",
        data: {
          fields
        }
      }).catch(response => {
        if (response.jqXHR) {
          response = response.jqXHR;
        }
        if (response && response.responseJSON && response.responseJSON.errors) {
          let wizardErrors = [];
          response.responseJSON.errors.forEach(err => {
            if (err.field === wizardId) {
              wizardErrors.push(err.description);
            } else if (err.field) {
              this.fieldError(err.field, err.description);
            } else if (err) {
              wizardErrors.push(err);
            }
          });
          if (wizardErrors.length) {
            this.handleWizardError(wizardErrors.join("\n"));
          }
          this.animateInvalidFields();
          throw response;
        }
        if (response && response.responseText) {
          const responseText = response.responseText;
          const start = responseText.indexOf(">") + 1;
          const end = responseText.indexOf("plugins");
          const message = responseText.substring(start, end);
          this.handleWizardError(message);
          throw message;
        }
      });
    },
    handleWizardError(message) {
      this.set("message", {
        state: "error",
        text: message
      });
      (0, _runloop.later)(() => this.set("message", null), 6000);
    }
  }, [["method", "i18nKey", [(0, _decorators.default)("wizardId", "id")]], ["method", "translatedTitle", [(0, _decorators.default)("i18nKey", "title")]], ["method", "translatedDescription", [(0, _decorators.default)("i18nKey", "description")]], ["field", "displayIndex", [(0, _decorators.default)("index")]], ["method", "fieldsById", [(0, _decorators.default)("fields.[]")]]]));
});