define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-cta", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/mixins/subscription", "I18n"], function (_exports, _component, _decorators, _subscription, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_subscription.default, dt7948.p({
    tagName: "a",
    classNameBindings: [":btn", ":btn-pavilion-support", "subscriptionType"],
    attributeBindings: ["title"],
    i18nKey(subscribed) {
      return `admin.wizard.subscription.cta.${subscribed ? "subscribed" : "none"}`;
    },
    icon(subscribed) {
      return subscribed ? "far-life-ring" : "external-link-alt";
    },
    title(i18nKey) {
      return _I18n.default.t(`${i18nKey}.title`);
    },
    label(i18nKey) {
      return _I18n.default.t(`${i18nKey}.label`);
    },
    click() {
      window.open(this.subscriptionCtaLink, "_blank").focus();
    }
  }, [["method", "i18nKey", [(0, _decorators.default)("subscribed")]], ["method", "icon", [(0, _decorators.default)("subscribed")]], ["method", "title", [(0, _decorators.default)("i18nKey")]], ["method", "label", [(0, _decorators.default)("i18nKey")]]]));
});