define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-no-access", ["exports", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "discourse-common/utils/decorators", "@ember/component", "@ember/string", "discourse-common/lib/get-url"], function (_exports, _customWizard, _decorators, _component, _string, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-no-access", "reasonClass"],
    reasonClass(reason) {
      return (0, _string.dasherize)(reason);
    },
    siteName() {
      return this.siteSettings.title || "";
    },
    actions: {
      skip() {
        if (this.currentUser) {
          _customWizard.default.skip(this.get("wizardId"));
        } else {
          window.location = (0, _getUrl.default)("/");
        }
      }
    }
  }, [["method", "reasonClass", [(0, _decorators.default)("reason")]], ["method", "siteName", [_decorators.default]]]));
});